@import "../../styles/variables";
@import "../../styles/fonts";

.typeContainer {
  padding: 10px 14px;
  border-bottom: 1px solid $accordion-border-color;

  &:last-child {
    border-bottom: none;
  }
}

.typeTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 14px;
}

.typeTitle {
  display: flex;
  align-items: center;
  line-height: 1;
}

.text {
  padding-top: 3px;
  text-transform: uppercase;
}

.lookName {
  padding-top: 3px;
  @include paragraph;
}

.typeIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.colorsContainer {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  overflow-x: auto;
}

.colorItem {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 10px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAALElEQVQYV2P8////fwYkwMjIKM2ILAgSAMnDBWECcEFkAbAgAwODFLKZIDYAiyYQiKtJnA0AAAAASUVORK5CYII=")
    repeat;

  &.active {
    border: 1px solid black;
  }
}

.rangeWrapper {
  border-top: 1px solid $canvas-buttons-border-color;
  padding-top: 10px;
  display: none;

  &.active {
    display: block;
  }
}
