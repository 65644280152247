@import "../../styles/variables";
@import "../../styles/fonts";
.root {
    position: relative;
    width: 100%;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
    padding: 40px 10px;
    margin: auto;
}

.wrapper {
    display: flex;
}

.canvasContainer {
    width: 50%;
    position: relative;
    padding-right: 20px;
    &.live {
        max-width: initial;
    }
}

.makeUpContainer {
    // position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.tabsWrapper {
    width: 50%;
    position: relative;
}

.controlWrapper {
    width: 100%;
    height: 100%;
    // overflow: auto;
    // position: relative;
}

.tabsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.loaderContainer {
    padding: 40px;
}

.tab {
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: $accordion-tab-text-color;
    padding: 12px 24px;
    cursor: pointer;
    background: $accordion-tab-background-color;
    white-space: nowrap;
    &.active {
        cursor: default;
        background: $accordion-tab-background-active-color;
        color: $accordion-tab-text-active-color;
    }
}

.splitter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 50%;
    color: $accordion-tab-text-color;
    padding: 5px;
    border: 2px solid $accordion-tab-text-active-color;
}

.mobileCart {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .root {
        min-height: calc(100vh - #{$header-height} * 0.5 - #{$footer-height} * 0.5);
        padding: 10px;
    }
    .wrapper {
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }
    .canvasContainer,
    .tabsWrapper {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
    .makeUpContainer {
        width: 100%;
        position: relative;
    }
}