@import "../../styles/variables";
@import "../../styles/fonts";
.mianContainerCTA {
    width: 100%;
    float: left;
    min-height: 300px;
    padding: 30px 0px;
    background: linear-gradient( -15deg, #FEAC47, #F8486E 60%);
    height: auto;
}

.mainTitle {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    font-size: 50px!important;
    line-height: 60px!important;
    margin-bottom: 20px!important;
    text-align: center;
}

.contactUsTitle {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    font-size: 16px!important;
    line-height: 30px!important;
    margin-bottom: 20px!important;
    text-align: center;
}

.getStartedContainer {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    margin-bottom: 20px!important;
    text-align: center;
    margin-top: 25px;
}

.anchorGetStarted {
    border-color: rgba(255, 255, 255, 0.75);
    color: rgb(255, 255, 255);
    visibility: visible;
    background-color: transparent;
    margin-top: 10px !important;
    padding: 15px 50px!important;
    border-radius: 16px!important;
    border-width: 3px;
    border-style: solid;
    font-weight: 600!important;
    text-transform: uppercase;
    margin-bottom: 0!important;
    transition: opacity .45s cubic-bezier(.25, 1, .33, 1), transform .45s cubic-bezier(.25, 1, .33, 1), border-color .45s cubic-bezier(.25, 1, .33, 1), color .45s cubic-bezier(.25, 1, .33, 1), background-color .45s cubic-bezier(.25, 1, .33, 1), box-shadow .45s cubic-bezier(.25, 1, .33, 1);
}

@media screen and (max-width: 980px) {
    .mianContainerCTA {
        min-height: 165px;
        padding: 10px 0px;
    }
    .mainTitle {
        font-size: 20px !important;
        line-height: 40px !important;
        margin-bottom: 10px !important;
    }
    .contactUsTitle {
        margin-bottom: 10px!important;
    }
    .getStartedContainer {
        margin-bottom: 10px!important;
        margin-top: 15px;
    }
    .anchorGetStarted {
        margin-top: 10px !important;
        padding: 10px 20px!important;
    }
}