@import "../../styles/variables";

.rangeWrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 2rem;
  width: 100%;
  padding: 25px 0;
}

.activeTrack {
  height: 5px;
  background: $canvas-button-border-color;
}

.sliderContainer {
}

.hidden {
  display: none;
}

.slider {
  appearance: none;
  background: $canvas-button-border-color;
  border: 1px solid $canvas-button-border-color;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 2rem;
  margin-left: -0.5rem;
  margin-top: -1.1rem;
  outline: none;
  position: absolute;
  top: 50%;
  width: 2rem;
  &:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
}

.track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
}
